
import { useLocation, useNavigate, useParams } from "react-router";
import { DocumentIcon, InvestigatorIcon } from "../../assets/common-svg/CommonSvg";
import CustomDetailComponent from "../../components/doctor-detail-component/DocotorDetail";
import { CustomWrapperCard } from "../../components/custom-product-cards/custom-wrapper-card/CustomWrapperCard";
import CustomTable from "../../components/custom-table/CustomTable";
import TrialInfoComponent from "../../components/trial-info-component/TrialInfoComponent";
import { Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SkeletonLoader from "../../utilites/SkeltonLoader";
import { phaseDetailsData } from "../../utils/tableData";
import PhaseApi from "../../services/phase";
import { setAssignedForm, setPhaseDetail } from "../../store/reducers/phase-reducer";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import SubjectApi from "../../services/subjectService";
import { useDispatch, useSelector } from "react-redux";
import SubAdminApi from "../../services/subAdminService";
import { AddNewSubject, AddNewSubjectPhase } from "../../utils/formik/initialValues";
import { addNewSubjectPhaseValidationSchema, addNewSubjectValidationSchema } from "../../utils/formik/validationSchema";
import CustomModel from "../../components/custom-model/CustomModel";
import DiseaseManagementApi from "../../services/diseaseService";
import { GenderList } from "../../utils/common";
import moment from "moment";
import { setSnackbar } from "../../store/reducers/snackBar";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import SelectFormModel from "../../components/custom-model/SelectFormModel";
import NoDataCard from "../../components/no-data-card";
import ProductApi from "../../services/productService";
import ConfirmationModal from "../../components/modal-contents/ConfirmationModal";

const convertSubjectData = (data, alignment, currPage) => {
  return data?.map((sub, index) => {
    let SerialNo = 10*(currPage-1)+(index+1);
    let transformId = sub?.id;
    let transformPatientId = sub?.patient_id || "--";
    let transformedSubjectInitial = sub?.first_name + " " + sub?.last_name || "";
    let transformedPatientStatus = sub?.life_status == 'lost' ? 'Lost To Follow Up': sub?.life_status;
    let transformedGender = sub?.gender;
    let transformeappointmentCount = sub?.appointmentCount;
    let transformedEditor = sub?.editorDetails?.name || "Not Assigned";
    let transformCycleCount = sub?.cycle
    let transformedStatus =
      sub?.status_details?.status.charAt(0).toUpperCase() +
      sub?.status_details?.status.slice(1);
    return alignment === "Subject"
      ? [
        SerialNo,
        transformPatientId,
        transformedSubjectInitial,
        transformedPatientStatus,
        transformeappointmentCount,
        transformedEditor,
        transformCycleCount,
        transformedGender,
        transformId,
      ]
      : [
        SerialNo,
        transformedSubjectInitial,
        transformeappointmentCount,
        transformedStatus,
        transformedGender,
        transformId,
      ];
  });
};

export const TrailsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const snackbar = useSelector((state) => state.snackBar);
  const { loginId, role_id } = useSelector((state) => state.loginData);
  const [phaseDetailLoading, setPhaseDetailLoading] = useState(false);
  const [isAddSubjectLoading, SetIsAddSubjectLoading] = useState(false);
  const [showAddSubjectButton, setShowAddSubjectButton] = useState(false);
  const [isFormLoading, SetIsFormLoading] = useState(false);
  const [isOpenFormModel, SetIsFormModel] = useState(false);
  const [isInvestigator,] = useState(role_id === 2);
  const [isEditor,] = useState(role_id === 5);
  const [phaseListDetails, setPhaseListDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [formTemplateList, setFormTemplateList] = useState([]);
  const [alignment, setAlignment] = useState("Subject");
  const [subjectData, setSubjectData] = useState(phaseDetailsData(alignment, role_id));
  const [status, setStatus] = useState([2]);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [currentPage, setCurrentpage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [openAddSubjectModal, setOpenAddSubjectModal] = useState(false);
  const [pIList, setPIList] = useState([]);
  const [subAdminId, setSubAdminID] = useState(null);
  const [subAdminList, setsubAdminList] = useState([]);
  const [statusDropdownList, setStatusDropdownList] = useState([]);
  const [statusId, setStatusId] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [patientCycleConfirm, setPatientCycleConfirm] = useState(false);
  const [patientCycleErrMess, setPatientCycleErrMess] = useState("")
  const [patientFormValues, setPatientFormValues] = useState({})
  const [patientCycleLoading, setPatientCycleLoading] = useState(false)
  const [modelData, setModelData] = useState({
    title: "Add New Patient",
    editTitle: "Edit Editor",
    children:
    isEditor ?
    [
      {
        type: "input",
        name: "firstName",
        label: "First Name",
        placeholder: "Enter First Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "lastName",
        label: "Last Name",
        placeholder: "Enter Last Name",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "gender",
        label: "Gender",
        placeholder: "Select gender",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "disease",
        label: "Disease",
        placeholder: "Select disease",
        data: [],
        IsRequired: true,
      },
      {
        type: "date",
        name: "dob",
        label: "Date of Birth",
        placeholder: "Select date",
        minDate: false,
        maxDate: new Date(),
        IsRequired: true,
      },
    ] : 

    [
      {
        type: "input",
        name: "firstName",
        label: "First Name",
        placeholder: "Enter First Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "lastName",
        label: "Last Name",
        placeholder: "Enter Last Name",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "gender",
        label: "Gender",
        placeholder: "Select gender",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "principal_investigator",
        label: "Principal Investigator",
        placeholder: "Select principal investigator",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "editor_id",
        label: "Editor",
        placeholder: "Select Editor",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "disease",
        label: "Disease",
        placeholder: "Select disease",
        data: [],
        IsRequired: true,
      },
      {
        type: "date",
        name: "dob",
        label: "Date of Birth",
        placeholder: "Select date",
        minDate: false,
        maxDate: new Date(),
        IsRequired: true,
      }
    ]

  });

  const handleNavigateToDetails = useCallback((row) => {
    const UpdatedNavigationArray = location.state;
    UpdatedNavigationArray?.push({
      name: "Patient Details",
      path: `${location.pathname}/patient-details/${row[row.length - 1]}`,
    });
    navigate(`patient-details/${row[row.length - 1]}`, {
      state: UpdatedNavigationArray,
    });
  }, []);

   // Function to get Editor for Patient Sub Admin by Value and Phase List ID
   const getEditorForSubjectSubAdmin = async (value) => {
    try {
      return await SubAdminApi.getEditorForSubjectSubAdmin(
        value,
        phaseListDetails?.id
      );
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));

    }
  };

  function arraysEqual(arr1, arr2) {
    // Check if the lengths of both arrays are the same
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Convert all elements to numbers, sort both arrays and compare
    let sortedArr1 = arr1?.map(Number)?.sort((a, b) => a - b);  // Convert to number and sort
    let sortedArr2 = arr2?.map(Number)?.sort((a, b) => a - b);  // Convert to number and sort

    for (let i = 0; i < sortedArr1?.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}


  const handleShowAddSubjectButton = (data) => {
    let showButton = false;

    if (data?.form_details) {
      // Check if all forms are approved
      showButton = data.form_details.every((value) => value?.is_approved !== false);
    }

    const tempArray = data.form_details.map((form)=>{
      return String(form.type.id)
    });
    
    const IsTempateIdsEqual = arraysEqual(tempArray,data.template_type_ids);
    

    if (showButton && role_id == 5) {
      setSubjectData((prev) => ({
        ...prev,
        TableSearchActions: {
          ...prev.TableSearchActions,
          actions: [{ key: "view", module: "subject", auth: 17 }, { key: "add", module: "subject", auth: 16 }],
        }
      }));
    }
    else {
      setSubjectData((prev) => ({
        ...prev,
        TableSearchActions: {
          ...prev.TableSearchActions,
          actions: [{ key: "view", module: "subject", auth: 17 }, { key: "add", module: "appointmentManager", auth: 122 }],
        }
      }));
    }

    return (showButton&&IsTempateIdsEqual);
  }

  const getPhaseDetails = async () => {
    setPhaseDetailLoading(true);
    try {
      const res = await PhaseApi.getPhaseListDetails(id);
      setPhaseListDetails(res.data || []);
      const showButtonBoolean = handleShowAddSubjectButton(res?.data);
      setShowAddSubjectButton(showButtonBoolean);
      setModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "principal_investigator"
            ? {
              ...child,
              data: res.data?.investigator_details?.principal_investigator,
            }
            : child
        ),
      }));
      setFormTemplateList(() => {
        return res?.data?.form_details?.filter((item) => {
          return (item?.is_approved === true)
        })
      }
      );
      dispatch(setAssignedForm(res?.data?.form_details))
      dispatch(setPhaseDetail(res.data));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setPhaseDetailLoading(false);
      setLoading(false);
    }
    // }
  };

  useEffect(() => {
    setLoading(true);
    const updatedSubjectData = phaseDetailsData(alignment, role_id);
    setSubjectData((prev) => ({
      ...prev,
      columnAlign: updatedSubjectData?.columnAlign,
      TableSearchActions: updatedSubjectData?.TableSearchActions,
      columns: updatedSubjectData?.columns,
    }));
    if (alignment === "Request") {
      setSubAdminID(null);
      setStatus([1, 3]);
    } else if (alignment === "Subject") {
      if (isInvestigator) {
        setStatus(null);
        return;
      }
      setStatusId(null);
      setStatus([2]);
    }
  }, [alignment]);

  const handleOpenAddSubjectModal = () => {
    setOpenAddSubjectModal(true);
  };

  async function getSubjectList() {
    try {
      const res = await SubjectApi.getSubjectList(
        phaseListDetails?.id,
        currentPage,
        10,
        tableFilters?.searchData,
        !isInvestigator ? isEditor && loginId : isInvestigator && subAdminId,
        role_id == 2 ? loginId : '',
        status
      );
      let rowData = convertSubjectData(res?.data || [], alignment, currentPage);
      setSubjectData((prev) => ({
        ...prev,
        rows: [...rowData],
      }));
      setTotalPage(
        Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
      );
      setCurrentpage(res?.page_details?.page);
      setLoading(false);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      setLoading(false);
      setSubjectData((prev) => ({
        ...prev,
        rows: [],
      }));
    }
  }

  useEffect(() => {
    if (phaseListDetails?.id) {
      if (
        tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " "
      ) {
        setLoading(true);
        getSubjectList();
      }
    }
  }, [
    currentPage,
    tableFilters,
    phaseListDetails,
    subAdminId,
    statusId,
    status,
  ]);

  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1)
  };

  const handleAddSubject = async (values, confirmation) => {
    // Normalize the array fields
    // // Create updated values
    SetIsAddSubjectLoading(true); // Reset loading state
    setPatientFormValues(values);
    const formData = new FormData();

    formData.append("first_name", values.firstName?.trim());
    formData.append("last_name", values.lastName?.trim());
    if (values.upload_file) {
      if (values.upload_file instanceof FileList) {
        for (let i = 0; i < values.upload_file.length; i++) {
          formData.append("files", values.upload_file[i]);
        }
      } else {
        formData.append("files", values.upload_file);
      }
    }
    formData.append(
      "gender",
      GenderList.find((op) => op?.id == values.gender).name
    );
    formData.append("dob", moment(values.dob).format("MM/DD/YYYY"));
    formData.append("disease_id", values.disease);
    formData.append("phase_id", phaseListDetails?.id);
    formData.append("phase_code", phaseListDetails?.phase_code);
    formData.append("phase_title", phaseListDetails?.title);
    formData.append(
      "investigator_id",
      isEditor ? 
      phaseListDetails?.investigator_details?.assigned_investigator?.id :
      values?.principal_investigator
    );
    formData.append("editor_id", isEditor ? loginId : values?.editor_id);
    formData.append("product_id", phaseListDetails?.product_id);
    formData.append("addCycle", confirmation || false);

    try {
      // API call to add phase
      const response = await SubjectApi.addSubject(formData);
      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Patient added successfully",
          color: "success",
        })
      );
      SetIsAddSubjectLoading(false); // Reset loading state
      setLoading(true);
      getPhaseDetails();
      // Close the modal
      setOpenAddSubjectModal(false);
    } catch (error) {
      SetIsAddSubjectLoading(false); // Reset loading state
      // Safely access the 'addCycle' key if it exists in the 'errors' array
      const addCycleError = error?.errors?.find(obj => 
        Object.prototype.hasOwnProperty.call(obj, 'addCycle')
      );
      // Log whether 'addCycle' exists in the error and the corresponding value if found
      if (addCycleError) {
          setPatientCycleErrMess(addCycleError?.addCycle)
          setPatientCycleConfirm(true);  // Set patient cycle confirmation only if addCycle is found
      } else{
        dispatch(
          setSnackbar({
            value: true,
            message: error?.message || error || "Failed to Add Patient",
            color: "error",
          })
        );
      }
      
    } finally{
      if(confirmation){
        setPatientCycleConfirm(false);
      }
    }
  };

  const hanldeFormSubmit = async (values) => {
    SetIsFormLoading(true); // Reset loading state
    let updatedValues = {
      title: values.form.title,
      form_template_id: values.form.id,
      phase_id: phaseListDetails.id,
      subject_id: subjectId,
    };

    try {
      // API call to add phase
      const response = await SubjectApi.addAppointment(updatedValues);
      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Appointment added successfully",
          color: "success",
        })
      );

      // Close the modal
      setSubjectId("");
      SetIsFormModel(false);
      SetIsFormLoading(false); // Reset loading state
      const UpdatedNavigationArray = location.state;
      UpdatedNavigationArray?.push({
        name: "Patient Details",
        path: `${location.pathname}/patient-details/${response?.data?.subject_id}`,
      });
      navigate(`patient-details/${response?.data?.subject_id}`, {
        state: UpdatedNavigationArray,
      });
    } catch (error) {
      SetIsFormModel(false);
      SetIsFormLoading(false); // Reset loading state
      dispatch(
        setSnackbar({
          value: true,
          message: error?.message || error || "Failed to add appointment",
          color: "error",
        })
      );
    } finally {
      setSubjectId("");
    }
  };

  // Function to get SubAdmin list Dropdown
  const getSubjectDropDown = async () => {
    try {
      const data = isInvestigator
        ? { investigator_id: loginId, phase_id: Number(id) }
        : { phase_id: Number(id) };
      const res = await SubAdminApi.getSubAdminDropdown(5, data);
      setsubAdminList(res.data);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Status  Dropdown
  const getStatusDropDown = async () => {
    try {
      const res = await SubjectApi.subjectStatusDropDown(id);
      setStatusDropdownList(() =>
        res.data.map((item) => {
          return {
            ...item,
            name: `${item.status[0].toUpperCase()}${item.status.slice(1)}`,
          };
        })
      );
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  const getDiseaseData = async () => {
    try {
      const decRes = await DiseaseManagementApi.getDiseaseManagementData();
      setModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "disease"
            ? { ...child, data: decRes?.data }
            : child.name == "gender"
              ? { ...child, data: GenderList }
              : child
        ),
      }));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };
  useEffect(() => {
    getDiseaseData();
    if (!isInvestigator) {
      getStatusDropDown();
    } else {
      getSubjectDropDown();
    }
    getPhaseDetails();
  }, []);


    useEffect(() => {
      if(pIList){
        setModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "principal_investigator"
              ? { ...child, data: pIList }
              : child
          ),
        }));
      }
    }, [pIList]);
  

     // Function to get Editor Dropdown
  const getEditorDropdown = async (Pi_id) => {
    try {
      if (Pi_id) {        
        let res = await ProductApi.subadminDropDown(5, Pi_id, phaseListDetails?.id);
        setModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "editor_id" ? { ...child, data: res?.data } : child
          ),
        }));
      } else {
        setModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "editor_id" ? { ...child, placeholder: "Select Editor", data: [] } : child
          ),
        }));
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };
  
   useEffect(() => {
    const fetchEdiorData = async () => {
      const result = await getEditorForSubjectSubAdmin(2);
      setPIList(result?.data)
    };
    if (phaseListDetails?.id) {
      fetchEdiorData();
    }
  }, [phaseListDetails]);

  const handleDropDownChange = (e, id) => {
    if (id === "selectSubject") {
      setSubAdminID(e.target.value);
      setCurrentpage(1)
    } else if (id === "selectStatus") {
      if (e.target.value) {
        setStatusId(e.target.value);
        setStatus([e.target.value]);
      } else {
        setStatusId(e.target.value);
        setStatus([1, 3]);
      }
      setCurrentpage(1)
    }
  };

  const handleAddAppointment = (row) => {
    if (formTemplateList && formTemplateList?.length != 0) {
      setSubjectId(row[row.length - 1]);
      SetIsFormModel(true);
    } else {
      dispatch(
        setSnackbar({
          value: true,
          message: "No appointment form assigned yet!",
          color: "error",
        })
      );
    }
  };

  const handlePatientCycleConfrim = (confirmation) => {
    setPatientFormValues({});
    if(!confirmation){
      setPatientCycleConfirm(false);
      setOpenAddSubjectModal(false);
      dispatch(
        setSnackbar({
          value: true,
          message: "Duplicate record not created.",
          color: "error",
        })
      );
      return
    }
    setPatientCycleLoading(true);
    handleAddSubject(patientFormValues, confirmation);
    setPatientCycleLoading(false);
  }


  const handleSelectFormError = (error) => {
    dispatch(
      setSnackbar({
        value: true,
        message: error,
        color: "error",
      })
    );
  }
  return (
    <div className="p-6">
      <SelectFormModel
        open={isOpenFormModel}
        setOpen={SetIsFormModel}
        isFormLoading={isFormLoading}
        data={formTemplateList}
        SetIsFormLoading={SetIsFormLoading}
        handleValue={hanldeFormSubmit}
        handleError={handleSelectFormError}
      />
      {patientCycleConfirm ? <ConfirmationModal
          open={patientCycleConfirm}
          setOpen={setPatientCycleConfirm}
          handleConfirmation={handlePatientCycleConfrim}
          errMessage={patientCycleErrMess}
          isLoading={patientCycleLoading}
        /> : null}
      <CustomModel
        modalInitialValues={isEditor ? AddNewSubject : AddNewSubjectPhase}
        initialValuesValidationSchema={isEditor ? addNewSubjectValidationSchema : addNewSubjectPhaseValidationSchema}
        open={openAddSubjectModal}
        setOpen={setOpenAddSubjectModal}
        modelData={modelData}
        handleModelValues={handleAddSubject}
        isLoadingBtn={isAddSubjectLoading}
        onChange={getEditorDropdown}
      />
      <div className="mb-3 md:mb-4">
        <BreadCrumb BreadCrumbsListing={
          [{
            name: phaseListDetails?.title,
            path: 'phases'

          }, {
            name: 'Details',
            path: location.pathname
          }]
        }

        />
      </div>
      {phaseListDetails && phaseListDetails.length != 0 ?
        <div className=" min-h-[25rem] bg-[#FFFFFF]   rounded-xl">
          <CustomWrapperCard
            loading={phaseDetailLoading}
            editDeleteBtn={false}
            disableAddButton={!showAddSubjectButton}
            data={phaseListDetails || {}}
            ShowPI={true}
            ShowEditorCount={isInvestigator}
            ShowProgressBar={false}
            handleAddButtonCard={handleOpenAddSubjectModal}
          />
          <hr className="ml-[1rem] mr-[1rem]   " />

          <TrialInfoComponent
            HeaderTitle={"Description"}
            ShowHeaderButton={false}
          >
            {phaseDetailLoading ? (
              <SkeletonLoader
                rowCount={1}
                isTable={false}
                width={500}
                height={20}
              />
            ) : (
              <Typography variant="p" className="break-words">
                {(phaseListDetails?.description?.[0]?.toUpperCase() + phaseListDetails?.description?.slice(1)) || "No Description Added"}
              </Typography>
            )}
          </TrialInfoComponent>
          <hr className="ml-[1rem] mr-[1rem]" />
          <TrialInfoComponent
            HeaderTitle={"Assigned Forms"}
            ShowHeaderButton={false}
          >
            <div className="flex gap-2 justify-start flex-wrap">
              {phaseDetailLoading ? (
                <SkeletonLoader
                  rowCount={2}
                  isTable={false}
                  width={200}
                  height={30}
                />
              ) : (
                <>
                  {phaseListDetails?.form_details?.length > 0
                    ? phaseListDetails?.form_details?.map((item) => (
                      <CustomDetailComponent
                        key={item?.id || ""}
                        BgColor="#2831720D"
                        FirstHeading={item.title || ""}
                        Icon={<DocumentIcon LightBlue={true} />}
                        ShowDeleteIcon={false}
                      />
                    ))
                    : "No Assigned Forms Found."}
                </>
              )}
            </div>
          </TrialInfoComponent>
          <hr className="ml-[1rem] mr-[1rem]   " />
          {isInvestigator &&
            <>
              <TrialInfoComponent
                HeaderTitle={"Assigned Editor"}
                ShowHeaderButton={false}
              >
                <div className="flex gap-2 justify-start flex-wrap">
                  {phaseDetailLoading ? (
                    <SkeletonLoader
                      rowCount={2}
                      isTable={false}
                      width={200}
                      height={30}
                    />
                  ) : (
                    <>
                      {phaseListDetails?.editor_details?.length > 0
                        ? phaseListDetails?.editor_details?.map((item) => (
                          <CustomDetailComponent
                            key={item?.id || ""}
                            BgColor="#2831720D"
                            FirstHeading={item?.name || ""}
                            Icon={<InvestigatorIcon />}
                            ShowDeleteIcon={false}
                          />
                        ))
                        : "No Assigned Editor Found."}
                    </>
                  )}
                </div>
              </TrialInfoComponent>
              <hr className="ml-[1rem] mr-[1rem]   " />
            </>
          }

          <div className="p-3">
            <CustomTable
              SearchbarBgColor="secondary"
              alignment={alignment}
              setAlignment={setAlignment}
              rows={subjectData?.rows}
              columnAlign={subjectData?.columnAlign}
              columns={subjectData?.columns}
              handleTableFilters={handleTableFilters}
              tableFilters={tableFilters}
              handleChangePage={handlePagination}
              loading={loading}
              currentPage={currentPage}
              totalpage={totalPage}
              TableData={{
                ...subjectData?.TableSearchActions,
                filters: subjectData?.TableSearchActions?.filters?.map((item) => {
                  if (item?.id === "selectSubject") {
                    return {
                      ...item,
                      MenuList: subAdminList,
                      dropDownValue: subAdminId,
                    };
                  } else if (item?.id === "selectStatus") {
                    return {
                      ...item,
                      MenuList: statusDropdownList,
                      dropDownValue: statusId,
                    };
                  }
                  return item;
                }),
              }}
              handleAddButton={handleAddAppointment}
              handleChangeDropDown={handleDropDownChange}
              handleNavigate={handleNavigateToDetails}
              DropdownBgColor={theme?.palette?.grey[200]}
            />
          </div>
        </div> : <div className="mt-4">
          <NoDataCard />
        </div>}
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
    </div>
  );
};
