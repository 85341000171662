export const navMenuItems = [
    {
        name: "Dashboard",
        icon: "dashboard",
        link: "/",
        allowedDept: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    },
    {
        name: "Trials",
        icon: "partnerDecision",
        link: "/partnerMeetings",
        allowedDept: [2],
        authorities: [140],
    },
];
export const PHONE_REGEX = /^(\+91[-\s]?)?[6-9]\d{9}$/;
export const NAME_REGEX = /^(?!\d+$)(?!\s+$)[a-zA-Z0-9\s.]+$/;
export const Hospital_REGEX = /^(?!\d+$)(?!\s+$)[a-zA-Z0-9\s.,]+$/;

export const StatusChangeHeading = "Are you sure you want to change Patient status ?"

export const TemplateStatus = [{ id: 1, name: "All" }, { id: 2, name: "Completed" }, { id: 3, name: "Saved as Draft" }]

export const GenderList = [{ id: 1, name: "Male" },{ id: 2, name: "Female" },{ id: 3, name: "Others" }] 

export const formTemplateDropdownList = [
    {
      id: "1",
      name: "Screening and Enrollment"
    },
    {
      id: "2",
      name: "Apheresis"
    },
    {
      id: "3",
      name: "Bridging Therapy"
    },
    {
      id: "4",
      name: "Conditioning Chemotherapy"
    },
    {
      id: "5",
      name: "Infusion"
    },
    {
      id: "6",
      name: "Post Infusion Follow Up"
    },
    {
      id: "7",
      name: "Short or Long Term Follow Up"
    }
  ];