// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      border: `1px solid #dee2e6 !important`,
    }
    
  };

  const OutlinedDisabledStyle = {
    '&.Mui-disabled': {
      color: `${theme.palette.grey[200]} !important`,
      backgroundColor: theme.palette.grey[600],
      border:'none',      
    }
  }

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          borderRadius: '.37rem',
          color: 'white',
          fontFamily: 'roboto',
          backgroundColor: theme.palette.info.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
          ...disabledStyle,
        },
        sizeLarge: {
          '&.MuiButton-containedSizeLarge': {
            padding: "0.5rem 0.875rem",
            borderRadius: "0.625rem"
          },
          ...disabledStyle,

        },
        sizeMedium: {
          '&.MuiButton-sizeMedium': {
            padding: "0.5rem 1rem",
            borderRadius: "0.625rem"
          }
        },
        fullWidth: {
          ' &.MuiButton-fullWidth': {
            padding: "0.625rem",
            fontSize: "0.875rem",
            fontWeight: 500,
            borderRadius: "0.375rem",
            marginTop: "0.313"
          },
        },
        contained: {

          '&.MuiButton-sizeMedium': {
            padding: "0.375rem 1rem",
            borderRadius: "0.625rem"
            // fontSize:"0.625rem",
            // fontWeight:500
          },

          ...disabledStyle,
        },
        outlined: {
          '&.MuiButton-outlinedPrimary': {
            borderColor: theme.palette.button.primary,
            fontSize: '0.875rem',
            fontWeight: '600',
            padding: '0.25rem 0.75rem',
            color: theme.palette.button.primary,
            backgroundColor: 'white'
            // lineHeight: '0.966rem',p
            // padding: '8px 0.625rem 8px 0.625rem'
          },

          ...OutlinedDisabledStyle,
        },
        text: {
          '&.MuiButtonBase-root.MuiButton-text':
          {
            borderRadius: "0.375rem !important",
            width: "9.375rem",
          }
        },
        string: {
          '&.MuiButtonBase-root.MuiButton-string':
          {
            borderRadius: "0.375rem !important",
            maxWidth: "12.5rem",
            height:"3.125rem !important",
            flexGrow:1
          }
        }
      }
    }
  };
}
