// import React, { useEffect, useState } from 'react';
import CustomInputField from "../custom-input/CustomInput";
import { ImageIcon } from "../../assets/common-svg/CommonSvg";
import { CustomMultiUpload } from "../custom-upload/CustomMultiUpload";
import { useSelector } from "react-redux";
import { getUserHasAccess } from "../../utils/authorities";
// import { useSelector } from 'react-redux';
export const FormOtherDetails = ({
  description,
  handleChangeDescription,
  handleRemoveFiles,
  appointmentData,
  patientLifeValue,
}) => {
  const LoginData = useSelector((state) => state.loginData);
  const userAuthority = LoginData?.auth || [];
  const isDisabled =
    patientLifeValue !== "alive"
      ? true
      : getUserHasAccess(userAuthority, "appointmentManager", 123)
      ? appointmentData?.readOnly ?? false
      : true;
      
  return (
    <div className="pt-2">
      <div className="flex gap-4 pt-3">
        <CustomMultiUpload
          label={"Upload Files"}
          name={"uploadFile"}
          placeholder="Upload File"
          readOnly={isDisabled}
          value={appointmentData?.media}
          showHeaderLabel={false}
          handleRemoveFiles={handleRemoveFiles}
          isDownload={
            getUserHasAccess(userAuthority, "appointmentManager", 123)
              ? !appointmentData?.readOnly ?? false
              : true
          }
          isRemove={isDisabled}
          isShowUpload={isDisabled}
          // accept={"image/*"}
          // error={Boolean(touched[child?.name] && errors[child?.name])}
          // touched={touched[child?.name]}
          // helperText={errors[child?.name]}
          fileIcon={<ImageIcon />}
          lable="Upload File"
          handleChange={(e) =>
            handleChangeDescription(e.target.files[0], "files")
          }
        />
      </div>
      <CustomInputField
        InputType={"text"}
        value={description ?? ""}
        onChange={(e) => handleChangeDescription(e.target.value, "description")}
        InputName={"description"}
        label={"Any Other Comments"}
        NoOfRows={2}
        disabled={isDisabled}
        MultiLine={true}
        placeholder={"Enter Here"}
        CustomClassName={"bg-[#EDEDED] text-[black] font-normal text-[18px]"}
      />
      
    </div>
  );
};
