import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomModal from '../custom-modal/CustomModal';
// import { DeleteModalIcon } from '../../assets/common-svg/CommonSvg';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';


const ConfirmationModal = ({ open, setOpen, handleConfirmation, isLoading = false, errMessage }) => {
    const handleClose = () => { setOpen(false) };

    return (
      <CustomModal open={open} setOpen={setOpen}>
        <div className="h-full flex flex-col gap-8 pl-3 pr-3 pt-4">
          {/* <div className="self-center">
            <DeleteModalIcon />
          </div> */}
          <Typography
            align="center"
            id="spring-modal-title"
            variant="h5"
            fontWeight={700}
            component="h5"
          >
            {errMessage}
          </Typography>
          <div className="flex justify-center gap-4">
            <Button
              variant="contained"
              className="w-[160px] h-[40px]"
              sx={{backgroundColor:"white",color:'currentColor','&:hover':{backgroundColor:"black",color:"white"},border:"1px solid Gray",}}
              onClick={() => {
                handleConfirmation(false);
                handleClose();
              }}
            >
              No
            </Button>
            <LoadingButton
              variant="contained"
            //   style={{ backgroundColor: "#FD5456" }}
              className="w-[160px] h-[40px]"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => handleConfirmation(true)}
            >
              <span>Yes</span>
            </LoadingButton>
          </div>
        </div>
      </CustomModal>
    );
}
ConfirmationModal.proptypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleDelete: PropTypes.func,
}
export default ConfirmationModal;