import React from "react"
import { ReactFormGenerator } from "react-form-builder2"
import '../../FormBuilder.css'
import "./form-style.css"



const FormGenerator = ({ formData }) => {

    const updateChildItems = (data) => {
        const ids = data.map(item => item.id);
        data.forEach(item => {
            if (item.childItems) {
                item.childItems = item.childItems.map(childId => ids.includes(childId) ? childId : null);
            }
        });

        return data;
    }

    const updatedData = updateChildItems(JSON.parse(JSON.stringify(formData)) || []);
    return <React.Suspense fallback={<div>Loading...</div>}>
        <ReactFormGenerator
            form_method="POST"
            hide_actions={true}
            read_only={true}
            skip_validations={false}
            data={JSON.parse(JSON.stringify(updatedData)) || []}
        />
    </React.Suspense>
}
export default FormGenerator